import React, { FC } from 'react';
import styled from 'styled-components';
import { UserInfo } from '../../../common/model/dto/user-info';
import OvUserListRow from './OvUserListRow';
import OvListTable from '../atoms/OvListTable';
import OvListTableRow from '../atoms/OvListTableRow';
import OvListTableHeaderField from '../atoms/OvListTableHeaderField';
import { useTranslation } from 'react-i18next';
import Variables from '../../../design-system/variables';

const OvUserList: FC<{ users: UserInfo[] }> = ({ users }) => {
  const headerNames = [
    'common.userFields.name',
    'common.userFields.email',
    'common.userFields.clinic',
    'common.userFields.latestScanDate',
    'common.userFields.regDate',
    'common.status',
    'common.operation',
    'common.action',
  ];
  const { t } = useTranslation();

  return (
    <WrapperBox>
      <OvListTable>
        <OvListTableRow>
          {headerNames.map((headerName) => (
            <OvListTableHeaderField>{t(headerName)}</OvListTableHeaderField>
          ))}
        </OvListTableRow>

        {users.map((user) => (
          <OvListTableRow>
            <OvUserListRow user={user} />
          </OvListTableRow>
        ))}
      </OvListTable>
    </WrapperBox>
  );
};

export default OvUserList;

const WrapperBox = styled.div`
  background: white 0 0 no-repeat padding-box;
  border-radius: 0.75rem;
  box-shadow: ${Variables.boxShadow.defaultBox};
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem 1rem;
  overflow-x: auto;
`;
