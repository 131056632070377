import { UserState } from '../states/user.state';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  activateScanningPlanForUser,
  cancelActiveScanningPlanForUser,
  deactivateUser,
  deletePasswordForDemoAccount,
  deleteUser,
  getDemoAccountPassword,
  getUserDetails,
  loadAllFertilityDisorders,
  loadAllUserRoles,
  loadUsers,
  setPasswordForDemoAccount,
  updateClinicLocationProviderData,
  updateOwnClinicLocationProviderData,
  updateOwnUserInfo,
  updateUserInfo,
  userInfo,
} from '../thunks/user.thunk';
import * as firebase from 'firebase/app';
import { UserInfo } from '../../common/model/dto/user-info';
import { FilterType } from '../../common/model/ui/filter.type';
import {
  DocumentFields,
  UserInfoFields,
} from '../../firebase/document-field.enums';
import { FilterModel } from '../../common/model/ui/filter.model';
import i18n from 'i18next';
import { AxiosError } from 'axios';
import { ListPaging } from '../../common/types';
import isNumber from 'lodash/isNumber';
import ReducerUtils from '../reducer.utils';
import { ClinicLocation } from '../../common/model/dto/clinic-location';
import { ClinicLocationProvider } from '../../common/model/dto/clinic-location-provider';
import { FullScriptOauthAccessToken } from '../../common/model/dto/fullscript/fullscript-oauth-access-token';
import { FilterUtils } from '../../common/utils/services/filter.utils';
import {
  managerRolePostfix,
  viewerRolePostfix,
} from '../../common/utils/auth.helpers';

const initialState: UserState = {
  currentUser: null,
  userList: [],
  userListPaging: {
    total: 0,
    offset: 0,
  },
  sortingOptions: [
    {
      value: DocumentFields.id,
      label: 'common.userFields.userId',
      direction: 'asc',
      multiDir: false,
    },
    {
      value: UserInfoFields.scan_created_at,
      label: 'common.userFields.latestScan',
      direction: '',
      multiDir: false,
    },
    {
      value: UserInfoFields.first_name,
      label: 'common.userFields.firstName',
      direction: '',
      multiDir: true,
    },
    {
      value: UserInfoFields.last_name,
      label: 'common.userFields.lastName',
      direction: '',
      multiDir: true,
    },
    {
      value: UserInfoFields.registration_date,
      label: 'common.userFields.regDate',
      direction: '',
      multiDir: true,
    },
  ],
  filters: [
    {
      fieldName: UserInfoFields.roles,
      label: 'common.userFields.roles',
      type: FilterType.select,
      options: [],
      value: '',
    },
    {
      fieldName: UserInfoFields.should_hide_not_registered_users,
      label: 'common.userSearchFilters.shouldHideNotRegisteredUsers',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.is_demo_account,
      label: 'common.userSearchFilters.showDemoAccounts',
      type: FilterType.radio,
      options: [
        {
          label: 'common.actions.yes',
          value: true,
        },
        {
          label: 'common.actions.no',
          value: false,
        },
        {
          label: 'common.actions.notSelected',
          value: '',
        },
      ],
      value: '',
    },
    {
      fieldName: UserInfoFields.known_reproductive_disorder,
      label: 'common.userFields.fertilityDisorder',
      type: FilterType.select,
      options: [],
      value: '',
    },
  ],
  showFilters: true,
  isLoading: false,
  areFertilityDisordersLoading: false,
  areAllUserRolesLoading: false,
  errorMessage: '',
  selectedUser: null,
  userListStoredQuery: '',
  selectedRole: '',
  selectedClinicLocation: undefined,
  demoAccountPassword: undefined,
  allUserRoles: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
    clearSelectedUser: (state) => {
      state.selectedUser = null;
    },
    setUserListPaging: (state, action: PayloadAction<ListPaging>) => {
      if (isNumber(action.payload.total)) {
        state.userListPaging.total = action.payload.total;
      }

      if (isNumber(action.payload.offset)) {
        state.userListPaging.offset = action.payload.offset;
      }
    },
    changeUserListSorting: (state, action: PayloadAction<UserInfoFields>) => {
      state.sortingOptions = state.sortingOptions.map((option) => {
        if (option.value === action.payload) {
          if (option.multiDir) {
            option.direction = option.direction === 'asc' ? 'desc' : 'asc';
          } else {
            option.direction =
              option.value === UserInfoFields.scan_created_at ? 'desc' : 'asc';
          }
        } else {
          option.direction = '';
        }

        return option;
      });

      state.userListPaging.offset = 0;
    },
    changeUserListFilter: (state, action: PayloadAction<FilterModel>) => {
      const index = state.filters.findIndex(
        (filter) => filter.fieldName === action.payload.fieldName
      );
      state.filters[index].value = FilterUtils.getParsedFilterValue(
        action.payload.value
      );
      state.userListPaging.offset = 0;
    },
    restoreSearchModel: (state, action: PayloadAction<any>) => {
      state.filters = ReducerUtils.restoreFilters(
        action.payload,
        state.filters
      );
      state.sortingOptions = ReducerUtils.restoreSorting(
        action.payload,
        state.sortingOptions
      );
      state.userListPaging.offset = action.payload.offset;
    },
    clearFilters: (state) => {
      state.filters = state.filters.map((filter, index) => {
        return {
          ...filter,
          value: initialState.filters[index].value,
          disabled: false,
        };
      });
      state.userListStoredQuery = '';
      state.userListPaging.offset = 0;
    },
    setUserListStoredQuery: (state, action: PayloadAction<string>) => {
      state.userListStoredQuery = action.payload;
    },
    clearUserList: (state) => {
      state.userList = [];
    },
    setSelectedRole: (state, action: PayloadAction<string>) => {
      state.selectedRole = action.payload;
    },
    setSelectedClinicLocation: (
      state,
      action: PayloadAction<ClinicLocation>
    ) => {
      state.selectedClinicLocation = action.payload;
    },
    setSelectedClinicRole: (state, action: PayloadAction<string>) => {
      state.selectedClinicRole = action.payload;
    },
    updateFullScriptUserInfo: (
      state,
      action: PayloadAction<FullScriptOauthAccessToken | undefined>
    ) => {
      if (state.currentUser) {
        state.currentUser.fullscript = action.payload
          ? {
              oauth: action.payload,
            }
          : action.payload;
      }
    },
    clearDemoAccountPassword: (state) => {
      state.demoAccountPassword = undefined;
    },
  },
  extraReducers: (builder) => {
    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(userInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userInfo.fulfilled, (state, action: PayloadAction<UserInfo>) => {
        state.currentUser = action.payload;
        let previouslySelectedClinicLocationId =
          action.payload.selected_clinic_location_id;
        const userClinicLocations = [
          ...action.payload.provider_clinic_locations,
          ...action.payload.patient_clinic_locations,
        ] as unknown as ClinicLocationProvider[];

        state.selectedRole =
          action.payload.selected_role ?? action.payload?.roles[0];

        if (previouslySelectedClinicLocationId) {
          const selectedRelation = userClinicLocations.find(
            (userClinicLocation) =>
              userClinicLocation.clinic_location.id ===
              previouslySelectedClinicLocationId
          );
          state.selectedClinicLocation = selectedRelation?.clinic_location;
          state.selectedClinicRole = selectedRelation?.clinic_role ?? 'Patient';
        } else if (userClinicLocations.length > 0) {
          state.selectedClinicLocation = userClinicLocations[0].clinic_location;
          state.selectedClinicRole = userClinicLocations[0].clinic_role;
        }

        const managerRoles = action.payload?.roles.filter((role) =>
          role.endsWith(managerRolePostfix)
        );

        if (
          !state.selectedRole &&
          !state.selectedClinicLocation &&
          !state.selectedClinicRole &&
          managerRoles.length > 0
        ) {
          state.selectedRole = managerRoles[0];
        }

        const viewerRoles = action.payload?.roles.filter((role) =>
          role.endsWith(viewerRolePostfix)
        );

        if (
          !state.selectedRole &&
          !state.selectedClinicLocation &&
          !state.selectedClinicRole &&
          viewerRoles.length > 0
        ) {
          state.selectedRole = viewerRoles[0];
        }

        state.isLoading = false;
      })
      .addCase(
        userInfo.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getUserDetails.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        getUserDetails.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateOwnUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateOwnUserInfo.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.currentUser = {
            ...state.currentUser,
            ...action.payload,
          };
          state.isLoading = false;
        }
      )
      .addCase(
        updateOwnUserInfo.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(activateScanningPlanForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        activateScanningPlanForUser.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = {
            ...state.selectedUser,
            ...action.payload,
          };
          state.isLoading = false;
        }
      )
      .addCase(
        activateScanningPlanForUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(cancelActiveScanningPlanForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        cancelActiveScanningPlanForUser.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = {
            ...state.selectedUser,
            ...action.payload,
          };
          state.isLoading = false;
        }
      )
      .addCase(
        cancelActiveScanningPlanForUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateUserInfo.pending, (state) => {
        state.errorMessage = '';
        state.isLoading = true;
      })
      .addCase(
        updateUserInfo.fulfilled,
        (
          state,
          action: PayloadAction<{
            userId: string;
            updatedProperties: any;
          }>
        ) => {
          if (
            Object.hasOwn(
              action.payload.updatedProperties,
              UserInfoFields.roles
            ) &&
            !action.payload.updatedProperties.roles
          ) {
            state.errorMessage = i18n.t(
              'common.userFields.errors.atLeastOneRoleHasToBeSelectedErrorMsg'
            );
            delete action.payload.updatedProperties.roles;
          }

          state.selectedUser = {
            ...state.selectedUser,
            ...action.payload.updatedProperties,
          };
          state.userList = state.userList.map((user) => {
            if (user.id === action.payload.userId) {
              return { ...user, ...action.payload.updatedProperties };
            }

            return user;
          });
          state.isLoading = false;
        }
      )
      .addCase(
        updateUserInfo.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(setPasswordForDemoAccount.pending, (state) => {
        state.errorMessage = '';
        state.isLoading = true;
      })
      .addCase(
        setPasswordForDemoAccount.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = {
            ...state.selectedUser,
            ...action.payload,
          };
          state.isLoading = false;
        }
      )
      .addCase(
        setPasswordForDemoAccount.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(getDemoAccountPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getDemoAccountPassword.fulfilled,
        (state, action: PayloadAction<{ demo_account_password: string }>) => {
          state.demoAccountPassword = action.payload.demo_account_password;
          state.isLoading = false;
        }
      )
      .addCase(getDemoAccountPassword.rejected, (state) => {
        state.isLoading = false;
      });

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deletePasswordForDemoAccount.pending, (state) => {
        state.errorMessage = '';
        state.isLoading = true;
      })
      .addCase(
        deletePasswordForDemoAccount.fulfilled,
        (state, action: PayloadAction<UserInfo>) => {
          state.selectedUser = {
            ...state.selectedUser,
            ...action.payload,
          };
          state.isLoading = false;
        }
      )
      .addCase(
        deletePasswordForDemoAccount.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateClinicLocationProviderData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateClinicLocationProviderData.fulfilled,
        (
          state,
          action: PayloadAction<{
            clinicLocationProviderId: string;
            updatedProperties: any;
          }>
        ) => {
          if (state.selectedUser?.provider_clinic_locations) {
            state.selectedUser.provider_clinic_locations =
              state.selectedUser.provider_clinic_locations.map(
                (clinicLocationProvider) => {
                  if (
                    clinicLocationProvider.id ===
                    action.payload.clinicLocationProviderId
                  ) {
                    return {
                      ...clinicLocationProvider,
                      ...action.payload.updatedProperties,
                    };
                  }

                  return clinicLocationProvider;
                }
              );
          }

          state.isLoading = false;
        }
      )
      .addCase(
        updateClinicLocationProviderData.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(updateOwnClinicLocationProviderData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateOwnClinicLocationProviderData.fulfilled,
        (
          state,
          action: PayloadAction<{
            clinicLocationProviderId: string;
            updatedProperties: any;
          }>
        ) => {
          if (state.currentUser?.provider_clinic_locations) {
            state.currentUser.provider_clinic_locations =
              state.currentUser.provider_clinic_locations.map(
                (clinicLocationProvider) => {
                  if (
                    clinicLocationProvider.id ===
                    action.payload.clinicLocationProviderId
                  ) {
                    return {
                      ...clinicLocationProvider,
                      ...action.payload.updatedProperties,
                    };
                  }

                  return clinicLocationProvider;
                }
              );
          }

          state.isLoading = false;
        }
      )
      .addCase(
        updateOwnClinicLocationProviderData.rejected,
        (state, action: PayloadAction<firebase.FirebaseError>) => {
          state.errorMessage = action.payload.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadUsers.pending, (state) => {
        state.isLoading = true;
        state.errorMessage = '';
      })
      .addCase(
        loadUsers.fulfilled,
        (state, action: PayloadAction<UserInfo[]>) => {
          state.userList = action.payload;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadAllFertilityDisorders.pending, (state) => {
        state.areFertilityDisordersLoading = true;
      })
      .addCase(
        loadAllFertilityDisorders.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.areFertilityDisordersLoading = false;
          const fertilityDisorderFilterIdx = state.filters.findIndex(
            (filter) =>
              filter.fieldName === UserInfoFields.known_reproductive_disorder
          );
          state.filters[fertilityDisorderFilterIdx].options = [];
          state.filters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.none'),
            value: 'None',
          });

          action.payload?.forEach((disorder) => {
            state.filters[fertilityDisorderFilterIdx]?.options?.push({
              label: disorder,
              value: disorder,
            });
          });

          state.filters[fertilityDisorderFilterIdx]?.options?.push({
            label: i18n.t('common.values.other'),
            value: 'Other',
          });
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(loadAllUserRoles.pending, (state) => {
        state.areAllUserRolesLoading = true;
      })
      .addCase(
        loadAllUserRoles.fulfilled,
        (state, action: PayloadAction<string[]>) => {
          state.areAllUserRolesLoading = false;
          state.allUserRoles = action.payload;

          const rolesFilterIdx = state.filters.findIndex(
            (filter) => filter.fieldName === UserInfoFields.roles
          );
          state.filters[rolesFilterIdx].options = [];
          state.filters[rolesFilterIdx]?.options?.push({
            label: i18n.t('common.values.none'),
            value: 'None',
          });

          action.payload?.forEach((role) => {
            state.filters[rolesFilterIdx]?.options?.push({
              label: role,
              value: role,
            });
          });
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state, action: PayloadAction<string>) => {
        state.userList = [];
        state.isLoading = false;
        state.errorMessage = '';
      })
      .addCase(
        deleteUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = (action.payload?.response as any)?.data?.message;
          state.isLoading = false;
        }
      );

    // @ts-ignore please leave the ts ignore for now for store and state related config files
    builder
      .addCase(deactivateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        deactivateUser.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.userList = [];
          state.isLoading = false;
          state.errorMessage = '';
        }
      )
      .addCase(
        deactivateUser.rejected,
        (state, action: PayloadAction<AxiosError>) => {
          state.errorMessage = (action.payload?.response as any)?.data?.message;
          state.isLoading = false;
        }
      );
  },
});

export const {
  clearCurrentUser,
  clearSelectedUser,
  setUserListPaging,
  changeUserListSorting,
  changeUserListFilter,
  clearFilters,
  setUserListStoredQuery,
  restoreSearchModel,
  clearUserList,
  setSelectedRole,
  setSelectedClinicLocation,
  setSelectedClinicRole,
  updateFullScriptUserInfo,
  clearDemoAccountPassword,
} = userSlice.actions;
export default userSlice.reducer;
